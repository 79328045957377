exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ilmoita-puutteesta-tsx": () => import("./../../../src/pages/ilmoita-puutteesta.tsx" /* webpackChunkName: "component---src-pages-ilmoita-puutteesta-tsx" */),
  "component---src-pages-tietoa-sivustosta-tsx": () => import("./../../../src/pages/tietoa-sivustosta.tsx" /* webpackChunkName: "component---src-pages-tietoa-sivustosta-tsx" */),
  "component---src-pages-tietosuojaseloste-tsx": () => import("./../../../src/pages/tietosuojaseloste.tsx" /* webpackChunkName: "component---src-pages-tietosuojaseloste-tsx" */),
  "component---src-pages-yrityksille-tsx": () => import("./../../../src/pages/yrityksille.tsx" /* webpackChunkName: "component---src-pages-yrityksille-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-city-tsx": () => import("./../../../src/templates/city.tsx" /* webpackChunkName: "component---src-templates-city-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */)
}

